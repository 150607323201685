import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { NavLink } from 'react-router-dom';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components/macro';
import { defaultTheme } from '../shared/styles/theme';

const Wrapper = muiStyled(Box)`
  flex-grow: 1;
  background-color: ${defaultTheme.colors.ADTBlue};
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1023px) {
    height: 44px;
  }
`;
const LogoTabsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const StyledTabs = muiStyled(Tabs)`
  background-color: ${defaultTheme.colors.ADTBlue};
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
`;
const StyledTab = muiStyled(Tab)`
  color: white;
  opacity: unset;
  width: 100%;
  font-size: ${defaultTheme.fonts.header4};
  font-weight: 500;
  justify-content: flex-start;
  white-space: nowrap;
`;
const StyledNavLink = muiStyled(NavLink)`
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 4rem;
  text-decoration: none;
  align-items: center;
`;

const Avatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.ADTBlue};
    border: 1px solid ${(props) => props.theme.colors.white};
    border-radius: 100%;
    font-size: ${(props) => props.theme.fonts.header3};
    font-weight: 900;
`;

export {
    Wrapper,
    StyledTabs,
    StyledNavLink,
    StyledTab,
    Avatar,
    LogoTabsContainer,
};
