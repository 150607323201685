import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { routes } from '../routes';
import { ReactComponent as Logo } from '../shared/pics/Logo.svg';
import { defaultTheme } from '../shared/styles/theme';
import {
    Avatar,
    LogoTabsContainer,
    StyledNavLink,
    StyledTab,
    StyledTabs,
    Wrapper,
} from './NavbarStyled';
import { useAppSelector, usePermissionCheck } from '../redux/hooks';
import { selectProfile } from '../redux/userProfileSlice';

interface Props {
    onRouteClick?: () => void;
}

export const Navbar: React.FC<Props> = ({ onRouteClick }) => {
    const { profile } = useAppSelector(selectProfile);
    function a11yProps(index: string): any {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (
        event: React.SyntheticEvent,
        newValue: number
    ): void => {
        setValue(newValue);
    };

    const quickDemoPermission = 'quickdemo.run';
    const canStartDemo = usePermissionCheck(quickDemoPermission);

    // Filter out 'Start Demo' page if user does not have permission
    const filteredRoutes = routes.filter((route) => {
        return route.permission !== quickDemoPermission || canStartDemo;
    });

    return (
        <Wrapper data-testid="navbar">
            <LogoTabsContainer>
                <Logo
                    style={{
                        margin: '56px 0',
                        // eslint-disable-next-line max-len
                        filter: 'drop-shadow(1px 0 0 white) drop-shadow(-1px 0 0 white) drop-shadow(0 1px 0 white) drop-shadow(0 -1px 0 white)',
                    }}
                />
                <StyledTabs
                    scrollButtons={false}
                    orientation="vertical"
                    aria-label="Navigation bar"
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                        style: { display: 'none' },
                    }}
                >
                    {filteredRoutes.map((route, i) => (
                        <StyledNavLink
                            to={route.path}
                            end
                            key={i}
                            style={({ isActive }: { isActive: boolean }) => {
                                return {
                                    backgroundColor: isActive
                                        ? defaultTheme.colors.moonBlue
                                        : '',
                                };
                            }}
                        >
                            <StyledTab
                                key={i}
                                label={route.label}
                                icon={<route.icon />}
                                onClick={onRouteClick}
                                {...a11yProps(route.label)}
                                iconPosition="start"
                            />
                        </StyledNavLink>
                    ))}
                </StyledTabs>
            </LogoTabsContainer>
            <StyledNavLink to="/profile" onClick={onRouteClick}>
                {profile ? (
                    <Avatar data-testid="Avatar">{`${
                        profile.firstName?.at(0)?.toUpperCase() ?? ''
                    }${profile.lastName?.at(0)?.toUpperCase() ?? ''}`}</Avatar>
                ) : (
                    <AccountCircleIcon
                        sx={{
                            color: 'white',
                            width: '40px',
                            height: '40px',
                            marginBottom: '20px',
                        }}
                    />
                )}
            </StyledNavLink>
        </Wrapper>
    );
};
