import React from 'react';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin: 16px;
    height: 100%;
`;

const PageName = styled.div`
    display: flex;
    font-size: ${(props) => props.theme.fonts.header1};
    line-height: 65px;
    color: ${(props) => props.theme.colors.ADTBlue};
    @media (max-width: 1023px) {
        font-size: 28px;
        line-height: 30px;
        padding: 20px;
        justify-content: center;
        width: 100%;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    margin-top: auto;
    justify-content: end;
    gap: 8px;
`;

export const StartDemo: React.FC = () => {
    const validationSchema = yup.object({
        customerAddress: yup
            .string()
            .required('Customer address is required')
            .matches(/^[a-zA-Z0-9\s,.\-#']+$/, 'Invalid characters')
            .max(150, 'Must be less than 150 characters'),
    });

    async function onSubmit() {
        // Do nothing until AM-1572
        // console.log(`submit placeholder '${values.customerAddress.trim()}'`);
    }

    const formik = useFormik({
        initialValues: {
            customerAddress: '',
        },
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit,
    });

    return (
        <Container>
            <PageName>Start Field Sales Demo</PageName>

            <Form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    multiline
                    id="customerAddress"
                    name="customerAddress"
                    label="Customer Address"
                    inputProps={{ maxLength: 151 }}
                    value={formik.values.customerAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.customerAddress &&
                        Boolean(formik.errors.customerAddress)
                    }
                    helperText={
                        formik.touched.customerAddress &&
                        formik.errors.customerAddress
                    }
                    sx={{ m: '16px 0' }}
                />
                <ButtonsContainer>
                    <Button
                        color="primary"
                        variant="contained"
                        name="create"
                        type="submit"
                        sx={{ width: '128px', whiteSpace: 'nowrap' }}
                    >
                        Start Incident
                    </Button>
                </ButtonsContainer>
            </Form>
        </Container>
    );
};
